<template>
  <div class="page2">
    <Row class='content'>
      <Col span="7">
        <!--              <div class="circlePie" id="circlePie">-->
        <!--                <canvas id="main" width="500" height="500"></canvas>-->
        <!--                <canvas id="dot"></canvas>-->
        <!--              </div>-->

        <div class="left-top-list">
          <div class="left-top">
            <span class='title'><span class="title-text">各项目总数据</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <dataTotal ref="dataTotal"></dataTotal>
          </div>
          <div class="bottom-list">
            <div class="bottom">
              <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
              <span class="angle1"></span>
              <span class="angle2"></span>
              <span class="angle3"></span>
              <span class="angle4"></span>
              <double-line ref="chart9" id="bottom_1"></double-line>
            </div>
          </div>
        </div>

      </Col>
      <Col span="10" class="centerBox">
        <div class="description">
          <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="describeList">
            <span v-if="describeList[chooseIndex]">{{ describeList[chooseIndex] }}</span>
            <span v-else style="text-align:center;font-size: 18px;">暂无简介......</span>
          </div>
        </div>
        <div class="chinaMap">
          <china-map ref="chinaMap"></china-map>
        </div>
      </Col>
      <Col span="7">
        <div class="list">
          <div class="right">
            <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <div class="chart-67">
              <bar-chart ref="chart3" id="left_3" :config="configData2"></bar-chart>
            </div>
            <!--                      <div class="chart-32">-->
            <!--                          <radar-chart ref="chart4" id="left_4" :data="officeRadarData"></radar-chart>-->
            <!--                      </div>-->
          </div>
        </div>
        <div class="list">
          <div class="right">
            <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <!--                        <div class="chart-32">-->
            <!--                            <radar-chart ref="chart7" id="right_3" :data="momentsRadarData"></radar-chart>-->
            <!--                        </div>-->
            <div class="chart-67">
              <single-area-chart ref="chart8" id="right_4"></single-area-chart>
            </div>
          </div>
        </div>
        <div class="list-bottom-pics">
          <div class="right-pics">
            <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <workPics ref="workPic" :isBig="false"></workPics>
          </div>
          <div class="right-pics">
            <span class='title' ><span class="title-text">{{ photoTitle }}</span></span>
            
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <honorPics ref="honor" :isLarge="false"></honorPics>
          </div>
        </div>
      </Col>
    </Row>
    <div class="tabNav">
      <div v-for="(item,index) in workName" :key="index" @click="chooseNav(item,index)"
           :class="index==chooseIndex?'on_workname':'workname'">{{ item.classifyName }}
      </div>
    </div>
  </div>
</template>

<script>
// const areaChart = ()=> import('./components/areaChart');
// const radarChart = () => import('./components/radar');
import {getDataApi,getHonorData} from "@/api/dataList";
// import {handleOldNewArrFn} from "@/lib/oldNewArr";

const barChart = () => import('./components/page2/barChart');
const chinaMap = () => import('./components/chinaMap');
// const doubleBarChart = ()=> import('./components/page2/doubleBarChart');
const singleAreaChart = () => import('./components/page2/singleAreaChart');
const doubleLine = () => import('./components/page2/doubleLine');
const workPics = () => import('./components/page2/workPictures');
const dataTotal = () => import('./components/page2/data-total');
const honorPics = () => import('./components/page2/HonoraryPics.vue');
// const threeBarChart = () => import('./components/page2/threeBarChart');
// const pieChart = () => import('./components/page2/pieChart');
// const doubleBars = () => import('./components/page2/doubleBars');
// import axios from 'axios'
export default {
  name: 'page2',
  props: ['selectRangeDate'],
  components: {
    // radarChart,
    barChart,
    singleAreaChart,
    doubleLine,
    workPics, //项目图片
    // pieChart,
    dataTotal,
    honorPics,
    chinaMap, //湖南地图
  },
  data() {
    return {
      //tabNav子菜单
      workName: [],
      //右下角图片标题
      picsTitle: '',
      photoTitle:'湖湘公益汇',
      h: -1, //滚动高度
      timer: null, //定时器
      describeList: [],
      resdata:[],
      everyPer: 0,
      xOffset: 0,
      circle: {
        x: 250,
        y: 250,
        radius: 218
      },
      resTotal:'',
      //项目图片数组
      workPicsList: [],
      chooseIndex: 0,
      // title: ['累计活动场次:150', '累计服务人数:30000', '累计志愿者人数:461', '累计覆盖学校数量:150', '累计投入款物（万元）:678','累计社会组织参与数:7',],
      configData2: {
        data: [213, 190, 137, 99, 63, 196, 248, 212, 248, 112]
      },
      warea: {x: 250, y: 250, max: 700},
      dots: [],
      resizeFn: null,
      animationFrame1: null,
      animationFrame2: null,
      centerBox: {
        width: 0,
        height: 0
      },
      vedioShow:false
    }
  },
  methods: {
    // 点击子菜单(导航栏)选择
    async chooseNav(item, index) {
      console.log(item,'--item00')
      const res = await getDataApi(item.classifyId);
      this.resdata = res.rows;
      this.chooseIndex = index;
      this.picsTitle = item.classifyName; //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = item.img;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角项目图片重新渲染滑动
      this.$refs.workPic.loading = false; //加载中
      // 荣誉图片或者荣誉视频部分
      const HonRes = await getHonorData(item.classifyId)
      if(HonRes.code == 200) {
        console.log(HonRes);
        if(HonRes.data.switchOption==1){
          //荣誉图片
          this.$refs.honor.shows = 1
          this.photoTitle = '荣誉图片'
           this.$refs.honor.videoUrl = ''
           this.$refs.honor.perfectPicsListL = []
           this.$refs.honor.honoraryPicsList = item.honorImage;
        }else if(HonRes.data.switchOption==2){
          //精彩视频
          this.$refs.honor.shows = 2
          if(HonRes.data.videoUrl){
            HonRes.data.videoUrl = JSON.parse(HonRes.data.videoUrl)
          }
          this.photoTitle = HonRes.data.videoUrl[0].title||'精彩视频'
          console.log( this.photoTitle,'title');
          this.$refs.honor.honoraryPicsList = []
          this.$refs.honor.perfectPicsListL = []
          this.$refs.honor.videoUrl = HonRes.data.videoUrl[0].url;
         
        }else if(HonRes.data.switchOption==3) {
          //精彩图片
          this.$refs.honor.shows = 3
          if(HonRes.data.photoUrl){
            HonRes.data.photoUrl = JSON.parse(HonRes.data.photoUrl)
          }
          this.photoTitle = '湖湘公益汇'
          this.$refs.honor.videoUrl = '';
          this.$refs.honor.honoraryPicsList = []
          this.$refs.honor.perfectPicsListL =  HonRes.data.photoUrl
        }
      }else {
        this.$refs.honor.honoraryPicsList = [];
        this.$refs.honor.videoUrl = ''
           this.$refs.honor.perfectPicsListL = []
      }
      // this.$refs.honor.honoraryPicsList = item.honorImage;
      // if(item.honorImage.length==0){
      //   //荣誉图片长度为0，显示视频
      //   this.vedioShow = true
      // }else {
      //   this.vedioShow = false
      // }
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load = false; //加载中

      this.$refs.chinaMap.seriesData = this.resdata
      //选择导航栏 把index对应传给choiceIndex 从而获取不同数据
      for (var i = 0; i < this.resdata.length; i++) {
        // this.resdata[i].choiceIndex = index;

        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue = Number(this.resdata[i].list[0].attent) + Number(this.resdata[i].list[0].money)
            + Number(this.resdata[i].list[0].schoolCount) + Number(this.resdata[i].list[0].serviceCount)
            + Number(this.resdata[i].list[0].soprtcount) + Number(this.resdata[i].list[0].volunteerCount)
        this.resdata[i].value = totalValue;
      }
      this.$refs.chinaMap.setMap(); //刷新地图数据
      //每次点击导航选项将之前数据置空 再进行下面操作
      this.$refs.chart9.soprtList = [];
      this.$refs.chart9.serviceList = [];
      this.$refs.chart3.volunteerList = [];
      this.$refs.chart3.schoolList = [];
      this.$refs.chart8.moneyList = [];
      this.$refs.chart8.socialList = [];
      //各城市的数据遍历获取
      this.resdata.forEach(it => {
        this.$refs.chart9.soprtList.push(it.list[0].soprtcount); //左下角活动场次
        this.$refs.chart9.serviceList.push(it.list[0].serviceCount) //左下角服务人次
        this.$refs.chart3.volunteerList.push(it.list[0].volunteerCount) //右上角志愿者人数
        this.$refs.chart3.schoolList.push(it.list[0].schoolCount) //右上角覆盖学校数量
        this.$refs.chart8.moneyList.push(it.list[0].money) //右中投入款物
        this.$refs.chart8.socialList.push(it.list[0].attent) //右中社会组织参与数
      })
      //刷新子组件数据
      this.$refs.chart9.setChart();
      this.$refs.chart3.setChart();
      this.$refs.chart8.setChart();
    },
  },
  created() {
  },
  mounted() {
    this.resizeFn = this.$debounce(()=> {
      this.$refs.chinaMap.setMap();
      //刷新子组件数据
      this.$refs.chart9.setChart();
      this.$refs.chart3.setChart();
      this.$refs.chart8.setChart();
    }, 500)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>

<style lang="less" scoped>
.page2 {
  height: 100%;
  width: 100%;
  padding: 8px 20px 20px;
  background: #03044A;
  overflow: hidden;
  position: relative;

  .tabNav {
    cursor: pointer;
    width: auto;
    margin: 0 auto;
    width: 100%;
    height: 4.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.5%;
    // left: 25%;
   
    .workname {
      color: #fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right: 1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }

    .on_workname {
      color: #4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }

  .content {
    height: 62%;

    .ivu-col {
      height: 100%;
    }

    .centerBox {
      height: 120%;

      .chinaMap {
        height: 95%;
      }

      .description {
        height: 34%;
        padding-bottom: 2%;
        margin-bottom: -1%;
      }

      .describeList {
        text-indent: 3%;
        display: flex;
        height: 96%;
        width: 98%;
        margin: 2% auto;
        align-items: center;
        color: rgb(167, 198, 235);
        line-height: 27px;
        font-size: 15px;
        font-family: "华文细黑", Courier New, Courier, monospace;
        overflow-y: scroll;

        span {
          width: 100%;
          height: auto;
          max-height: 100%;
        }
      }

      .describeList::-webkit-scrollbar { /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }

      .describeList::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #a1b3dc;
      }

      .describeList::-webkit-scrollbar-track { /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: rgb(8, 25, 79);
      }
    }

    .circlePie {
      height: 100%;
      padding: 0 0 40px;
      text-align: center;
      position: relative;
      margin-right: 15px;

      canvas {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
      }

      #dot {
        background: rgba(0, 0, 0, 0);
      }
    }

    .list-bottom-pics {
      display: flex;
    }

    .list, .list-bottom-pics {
      height: 52.3%;

      .left, .right, .center, .right-pics {
        width: 98%;
        height: 92%;
        border: 1px solid #0D2451;
        position: relative;
        margin-left: 15px;

        #left1, #left2, #left3, #right1, #right2, #right3, #center2 {
          height: 100%;
        }

        .chart-68 {
          width: 65%;
          height: 100%;
          float: left;
        }

        .chart-67 {
          width: 100%;
          height: 100%;
          float: left;
        }

        .chart-32 {
          width: 32%;
          height: 100%;
          float: left;
        }
      }
    }
  }

  .bottom, .right, .right-pics, .left-top, .description {
    width: 100%;
    border: 1px solid #0D2451;
    position: relative;
    box-sizing: border-box;
    //background: rgb(8, 25, 79);

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }

  .right-pics {
    width: 50% !important;
  }

  .left-top-list {
    height: 92%;
    //margin-top: 30px;
    width: 97%;

    .left-top {
      height: 100%;
    }
  }

  .bottom-list {
    margin-top: 22px;
    height: 63%;
    width: 100%;

    .bottom {
      width: 100%;
      height: 100%;
      border: 1px solid #0D2451;
      position: relative;
    }
  }

  .right-bottom {
    width: 100%;
    padding-right: 0;

    .bottom1 {
      width: 100%;
    }
  }

}
</style>
